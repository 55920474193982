@import '../../css/scss/index.scss';

.formsPage {

    .defaultCard {

        .card-header {

            font-family: $fontRegular;
            color: $blueBlackLogo;
            padding: 5px 10px;
            h1 {
                font-size: 30px;
                margin: 0;
                line-height: 1;
                &.error {color: red;}

            }

            p {
                margin: 0;
                color: $primary-color;
            }

        }

    }

    .formFields {

        header {
            text-align: center;
        }

        .pdfUrl {
            display: block;
            padding: 20px 0;
            text-align: center;

            color: $primary-color;
        }

        .MuiFormControl-root {
            margin: 0 0 10px 0;
            width: 100%;
        }

        .MuiDivider-root {
            background-color: rgb(76 76 76);
        }
    

        .titles {

            font-size: 20px;

            h2 {
                    color: $primary-color;
                    display: inline-block;
                    font-size: 20px;
                    text-align: right;

                }

            p {
                font-size: 14px;
                display: inline-block;
            }
                
                
            
        }

        .accordions {
          
            margin-bottom: 20px;

        }
        
        p.smallPText {
            font-size: 16px;
        }

        .childrens {

            /* background: #fbfbfb; */
            margin: 0 0 20px 0;

            .children {
                padding: 20px 10px 10px;
                border-bottom: 2px solid #d5d5d5;
            }

        }

        .selectInput {

            margin-bottom: 25px;

            legend {

                /* .muirtl-1z7n62 */
                visibility: visible;
                background: white;
                line-height: 1;
                color: $labelColor;
                max-width: inherit;
    
            }

            .Mui-error {

                legend {
                    color: $errorColor;
                }

            }

        }
        

        .dateWrapper {

            position: relative;
            margin-bottom: 20px;

            .titleDate {
                position: absolute;
                z-index: 10;
                color: $labelColor;
                background: white;
                font-size: 13px;
                top: -10px;
                right: 10px;
                padding: 0 6px;
            }
                
            

        }
        

        .react-datepicker-wrapper {

            input {
                width: 100%;
                border: 1px solid $borderColor;
                border-radius: 5px;
                padding: 15px 15px 12px;
                font-size: 16px;
            }

        }

        .signCont {

            margin: 0 0 20px 0;

            h3 {
                color: $primary-color;
                display: inline-block;
                font-size: 20px;
            }

            .canvasCont {
                border: 1px solid $borderColor;
                border-radius: 5px;
                padding: 5px;

                width: 100%;
                max-width: 250px;
                margin: 0 auto;

                canvas {
                    width: 100%;
                }

                &.error {
                    border-color: $errorColor;
                }
            }

            .btnCont {
                width: 100%;
                max-width: 250px;
                margin: -5px auto 0;
                
                text-align: left;

                button {
                    background: none;
                    border: none;
                    padding: 0;
                    color: grey;
                    font-size: 16px;
                    width: 110px;
                    display: inline-block;
                    text-align: left;
                }
            }

        }

    }


}

.addFiles {

    border-top: 1px solid $borderColor;
    margin: 0 0 20px 0;
    padding: 10px 0 0 0;

    h2 {
        font-size: 25px;

        small {
            color: grey;
            font-size: 80%;
        }
    }

    h3 {
        color: $primary-color;
        display: inline-block;
        font-size: 20px;
        text-align: center;
        margin: 0;
    }

    .fileCont {

        text-align: center;
        border-bottom: 1px solid $borderColor;
        margin: 20px 0;
        padding: 20px 0;

        .catName, .imgCol {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .user-file {
            width: 100%;
            margin: 0px auto 00px;

            p {
                font-size: 15px;
                line-height: 1;
                margin: 0;
            }

            img {


                &.noPic {
                    opacity: .2;
                    border: 2px dashed grey;
                    border-radius: 10px;
                    padding: 10px;
                }

                &:hover {
                    opacity: 1;
                }

            }

        }
    }

}