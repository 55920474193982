
$backgroundColor: white;

$blackfontText: #1F2631;

$primary-color: #f28b22;

$blueBlackLogo: #2b3c57;

$primary-color-hover: #8540a1;


$labelColor: rgba(0, 0, 0, 0.6);

$borderColor: #c9c9c9;
$errorColor: #dc3545;

