/* MOBILE MENU! */

.mobileMenu .MuiPaper-root {

    background: white;
    color: white;

}


.mobileMenu .m_header {

    position: relative;
    padding: 30px 0;

    .logoUp {
        width: 150px;
        display: block;
        margin: 10px auto 0;
    }
}



.mobileMenu .WaveProjectC {

    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding: 0 0 10px 0;

    img {
        width: 150px;
    }

}

.mobileMenu .m_header .fakeClose {
    position: absolute;
    left: 10px;
    top: 13px;
    width: 10px;
}

.mobileMenu .m_header .logoClient {
    display: block;
    width: 200px;
    margin: 10px auto;    
}

.mobileMenu .m_header .logoClient img {
    width: 100%;
    display: block;
}


.mobileMenu .userData {
    margin: 30px 0 0 0;
}

.mobileMenu .userData figure {
    display : inline-block;
    padding: 0 10% 0 0;
    vertical-align: middle;
}

.mobileMenu .userData figure div {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: center center no-repeat white;
    background-size: cover;
}

.mobileMenu .userData .textCont {
    margin: 0px 10px 7px 0;
    display : inline-block;
    vertical-align: middle;
}

.mobileMenu .userData .textCont h3 {

    color: white;
    font-weight: bold;
    font-size: 16px;

}

.mobileMenu .mainMenuNav {
    padding: 0 10% 0 0;
}

.mobileMenu .mainMenuNav a {
    
    display: block;
    width: 100%;
    color: black;
    margin: 0 0px 12px 0;
    text-decoration: none;
    font-size: 18px;

}


.mobileNavPage {

    background: white;
    text-align: center;
    padding: 10px 0;

    border-radius: 0 0 10px 10px;

    -webkit-box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.29);
    box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.29);

    margin: 0 0 10px 0;

    .logo {
        width: 100px;
    }

    .MuiButton-root {
        position: absolute;
        top: 10px;
        right: 0;

        
    }

}

/* END MOBILE MENU! */

/* .mobileOpenBtn {
    position: absolute !important;
    top: 0;
    margin: 39% 0 0 0 !important;
} */



/* // GENERAL MOBILE! */
.Mobile {

    .credit {
        img {
            width: 120px;
        }
    }

    .loader {
        right: 50%;
    }

    .firstRegister {
        
        width: 80%;

        header {
            h1 {
                margin-top: 10px;
            }
            p {
                font-size: 16px;
            }
        }

        .line {
            min-height: inherit;
            margin: 0 0 10px 0;

            .singIn {
                margin: 0 0 5px 0;
            }
        }



    }

    .tableLoginRegister {
        display: block;
        min-height: inherit;

        .ColLogin.LColLogin {
            position: relative;
            display: block;
            padding: 0;
            width: 100%;
            vertical-align: top;
        }

    }
    
    .picRight.ColLogin {
        display: none;
    }

    .logoDown {
        position: relative;
        width: 100%;
        display: block;
        background: white;
        margin: 30px 0 0 0;
    }

    .whiteBG {
        background: white;
    }

    .mobileLoginHeader {

        padding: 10px 0 0 0;

        .logoNathan {
            width: 100px;
            display: block;
            margin: 0 auto 10px;
        }

    }


    .mobileCont {
        max-width: 1000px;
        margin: 0 auto;
    }


}