@import "./scss/index.scss";
/* *************** MENU ************************* */

.defaultCard {

  box-shadow: $shadowDefault;

  .card-header {
    color: $primary-color;
    padding: 15px 5px;
    text-align: center;
    font-family: $fontBold;
  }

}

.formsPage {

  header.topHeader {
    text-align: center;
    padding: 20px 0;
    margin-bottom: 20px;
  }

}