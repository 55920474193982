@import "./scss/index.scss";

body {
  direction: rtl !important;
  text-align: right !important;
  font-family: $fontRegular !important;
  padding: 0px 0 0px 0;
  margin: 0;
  font-size: 19px !important;
  color: $blackfontText !important;
  /* min-width: 1200px; */
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: $backgroundColor;
  /* background: url('https://apinathan.bitcode.co.il/assets/img/default/screenBg.png') repeat; */
}

.boldTypeFamily {
  font-family: $fontBold;
}

.regularTypeFamily {
  font-family: $fontRegular;
}

/* .body-container, */

i.fas {
  margin: 0 5px;
}

.content-wrapper {
  padding: 0px 0px 0 0;
  min-height: 700px;
  min-width: 1000px;
}

.body-container.mobile,
.mobile .content-wrapper {
  min-height: 400px;
  min-width: inherit;
}

.mobile .content-wrapper {
  padding: 0;
}

.hidden {
  display: none;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p {
  direction: rtl;
}

strong {
  font-family: $fontRegular;
  font-weight: bold;
}

.wrapper {
  display: block;
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.wrapper:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.wrapper:after {
  content: "";
  display: block;
  clear: both;
}

.absoluteWrapper {
  position: absolute;
  right: 50%;
  margin: 0 -600px 0 0;
  top: 0;
}

.clear:before {
  content: "";
  display: block;
  clear: both;
}
.clear:after {
  content: "";
  display: block;
  clear: both;
}

header,
section,
footer,
aside,
nav,
article,
figure,
figcaption,
hgroup {
  display: block;
}

section {
  position: relative;
}

img,
img a {
  border: none;
}

.colTable {
  display: table-cell;
}

.fullWidth {
  width: 100%;
}

.bgs {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* ::-webkit-input-placeholder {padding-top: 0px;color: white !important; font-size: 80%;}
:-moz-placeholder {padding-top: 0px;color:  white !important; font-size: 80%;}
::-moz-placeholder {padding-top: 0px;color:  white !important; font-size: 80%;}
:-ms-input-placeholder {padding-top: 0px;color: white !important; font-size: 80%;} */

.form-wrapper .MuiFormControl-root {
  width: 100%;
  margin: 0px;
  padding: 0;
}

.form-wrapper .makeStyles-container-1 {
  display: inline-block;
}

.form-wrapper .MuiInputBase-input.MuiInput-input {
  font-family: $fontRegular;
  font-size: 18px;
}

.form-wrapper .MuiFormLabel-root {
  right: 0;
}

.form-wrapper
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  text-align: right;
  font-family: $fontRegular;
  font-size: 18px;
}

.form-wrapper .MuiFormLabel-root.Mui-focused,
.form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
  right: -65px !important;
}

.form-wrapper > div {
  display: inline-block;
}

.form-wrapper .MuiFormHelperText-root {
  text-align: right;
}

.form-wrapper .MuiFormHelperText-root span {
  color: red;
}

.form-wrapper > div > span {
  display: block;
  color: red;
  padding: 20px 0;
}

.form-wrapper button.sendBtn {
  font-family: $fontRegular;
  font-size: 18px;
  border-color: none;
  color: white;

  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;

  background: #e02b79;
  vertical-align: top;
  margin: 0px 0px 30px 0;
  padding: 15px 60px;
}

.form-wrapper button.sendBtn:hover,
.form-wrapper button.sendBtn:focus {
  background: black;
  color: white;
}

.form-wrapper button.sendBtn.btn-danger {
  background: #a00;
  color: yellow;
}

.form-wrapper .MuiInput-underline::after {
  border-color: black !important;
}

.form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
  color: $blackfontText !important;
}

.form-wrapper
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: $blackfontText;
}

.owl-carousel .owl-nav {
  top: 0;
  position: absolute;
  width: 100%;
  margin: 20% 0 0 0;
}

.owl-carousel .owl-nav span {
  display: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-prev:focus {
  /* background: url('../img/icons/left.svg') center center no-repeat ; */
  background-size: 100%;
  width: 28px;
  height: 28px;
  left: -50px;
  position: absolute;
}
.owl-carousel .owl-nav button.owl-next,
.owl-theme .owl-nav button.owl-next:hover,
.owl-theme .owl-nav button.owl-next:focus {
  /* background: url('../img/icons/right.svg') center center no-repeat; */
  background-size: 100%;
  width: 28px;
  height: 28px;
  position: absolute;
  right: -50px;
}

.owl-theme .owl-dots {
  margin: 20px 0;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 5px 3px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot:focus span {
  background: #e02b79;
}

.credit {
  margin: 20px 0 0 0;
  text-align: center;
  padding: 10px 0;
}

.credit img {
  width: 150px;
}

.Page404 {
  text-align: center;
  padding: 80px 0;
}

.Page404 img {
  width: 400px;
}

.Page404 a:hover img,
.Page404 a:focus img {
  width: 410px;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}

.bounce2 {
  -moz-animation: bounce2 2s infinite;
  -webkit-animation: bounce2 2s infinite;
  animation: bounce2 2s infinite;
}

@keyframes bounce3 {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -moz-transform: translateX(-6px);
    -ms-transform: translateX(-6px);
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  60% {
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

.bounce3 {
  -moz-animation: bounce3 2s infinite;
  -webkit-animation: bounce3 2s infinite;
  animation: bounce3 2s infinite;
}

@keyframes bounce1 {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  60% {
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.bounce1 {
  -moz-animation: bounce1 2s infinite;
  -webkit-animation: bounce1 2s infinite;
  animation: bounce1 2s infinite;
}

h1 {
  margin: 0 0 20px 0;
  font-size: 32px;
  /* font-family: "open_cond","helvetica","arial"  !important;; */
  font-weight: 100;
}

.AccPage img.logo {
  width: 100px;
}

.AccPage .columns {
  padding: 50px 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.AccPage .columns .text1 {
  margin: 0 0 20px 0;
}

.AccPage .columns .text2 {
  margin: 20px 0 0 0;
}

.AccPage .columns .text2 a {
  margin: 0 0 10px 0;
}

.AccPage .columns h3 {
  font-size: 18px;
  font-family: $fontRegular;
}

/*  MENU */

.navbar {
  padding-right: 10px;
}

.navbar .logOutBtn {
  margin: 0 0px 0 20px;
  padding: 0;
  font-size: 20px;
  background: none;
  border: none;
  color: white;
}
.navbar-brand {
  margin: 0 20px 0 0px;
}

.navbar-brand img {
  width: 100px;
}

/* END MENU */

.loginPage .credit {
  margin: 20px 0 0 0;
  width: 100%;
}

.HeaderDefault {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  margin: 0 0 20px 0;
  padding: 5px 0;
}

.HeaderDefault a {
  color: white;
  text-decoration: none;
}

.HeaderDefault a:hover,
.HeaderDefault a:focus {
  font-family: $fontRegular;
  text-decoration: underline;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal.show .modal-dialog {
  top: 0;
  margin-top: 15%;
}

.boostrapInvertcornersLeft {
  margin-right: -1px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  margin-left: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.boostrapInvertcornersRight {
  margin-left: -1px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  margin-right: 0 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.boostrapFaIcons {
  font-size: 18px;
  padding: 5px 0;
}

.loader {
  position: fixed;
  top: 20%;
  width: 100px;
  right: 59%;
  margin: 0 -50px 0 0;
  opacity: 0.2;
  display: none;
  z-index: 50;
}

.loader.active {
  display: block;
}

.modalSite .modal-dialog {
  width: 95%;
  margin: 10% auto;
}

.modalSite .modal-content {
  border-radius: 10px;
}

.modalSite .modal-body {
  padding: 5px 15px 30px;
}

.modalSite .closeBtn {
  color: $blackfontText;
  padding: 0;
  background: transparent;
  border: none;
  font-family: $fontBold;
}

.modalSite header {
  padding: 5px 0 0 0;
  text-align: center;
}

.alertModal .Message {
  padding: 10px 5px;
}

.alertModal .Message h2 {
  text-align: center;
  margin: 0 0 15px 0;
}

.alertModal .Message h3.kupotH3 {
  text-align: center;
  font-size: 20px;
  font-family: $fontBold;
}

.alertModal .Message h3.kupotH3 strong {
  display: inline-block;
}

.alertModal.error .Message h2 {
  color: red;
}

.alertModal.success .Message h2 {
  color: green;
}

.alertModal .Message p {
  text-align: center;
}

.alertModal .closeBtnCont {
  text-align: center;
}

.alertModal .closeBtnCont button {
  background: #0cc572;
  border: none;
}

.alertModal .toSupplier {
  text-align: center;
  margin: 0 0 50px 0;
}

.validatorInputError {
  font-family: $fontRegular;
  color: red;
  margin: -15px 0 3px 0;
  text-align: center;
  font-size: 16px;
}

.form-floating > label {
  right: 0 !important;
  left: auto !important;
  margin-right: 20px;
  font-size: 16px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  font-size: 14px;
  position: absolute;

  margin: 0 5px 0 0;
  padding: 0;

  top: 8px;
  right: 0px;
  left: inherit;
}

.form-floating > .form-control.customSelectInput:focus ~ label,
.form-floating
  > .form-control.customSelectInput:not(:placeholder-shown)
  ~ label,
.form-floating > .customSelectInput .form-select ~ label {
  position: absolute;
  right: 0;
  margin: 0;
  padding: 0;
  top: 8px;
  /* margin-right: -16px; */
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  font-size: 18px;
}

.fakeBtnInput {
  background: none;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  border-bottom: 1px solid #d4d7e6;
  text-align: right;
  font-size: 16px;
  padding: 13px 0 12px;
}

.fakeBtnInput.selectDB {
  background: url("../img/icons/SVG/downSelect.svg") left 16px no-repeat !important;
}

.fakeBtnInput.errorClass {
  border-color: #dc3545;
}

.errorH2 {
  color: red !important;
  font-family: $fontLight !important;
  font-size: 25px !important;
}

.okH2 {
  color: green !important;
  font-family: $fontLight  !important;
  font-size: 25px !important;
}

.pagesNumbers {
  margin: 20px 0 0px;
  text-align: center;
}

.pagesNumbers button {
  background: none;
  border: none;
  font-size: 14px;
}

.pagesNumbers button.active,
.pagesNumbers button:hover {
  color: #0058ff;
  font-family: $fontBold;
  font-size: 21px;
}

.goBackBtnCont {
  margin: 0 0 20px 0;
}

.goBackBtnCont .goBackBtn {
  border: none;
  background: none;
}

.goBackBtnCont .goBackBtn:hover {
  text-decoration: underline;
}

.Toastify {
  
  &__toast-body {

    font-family: $fontRegular;
    line-height: 1.2;
    padding-right: 5px;

  }


  /* &__close-button {
    align-self: center;
  } */
}

.react-datepicker__navigation--years-upcoming {
  transform: rotate(180deg);
  background: url("../img/icons/SVG/downSelect.svg") center 8px no-repeat !important;
}

.react-datepicker__navigation--years-previous {
  background: url("../img/icons/SVG/downSelect.svg") center 8px no-repeat !important;
}